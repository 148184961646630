.icon-24-hours {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-cross {
  width: 0.97em;
  height: 1em;
  fill: #353535;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: #ba8161;
}
.icon-hamburger {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
.icon-inst {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-left-arrow {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-left-long-arrow {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-like {
  width: 1em;
  height: 1em;
  fill: #010002;
}
.icon-mail {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-map {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-money {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-shipped {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-supermarket {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-telephone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-weight {
  width: 0.93em;
  height: 1em;
  fill: initial;
}
.icon-work-time {
  width: 1em;
  height: 1em;
  fill: initial;
}
