.simplecheckout-cart {
    &_quantity-input {
        text-align: center;
    }
    &_btn {

    }
    & thead {
        & th {
            background: $vc;
            color: $mainFontColor;
            vertical-align: middle;
        }
    }
}

.simplecheckout {
    &-left-column {
        width: 60%;
    }
    &-right-column {
        width: 38%;
    }
}

#simplecheckout_cart {
    & .simplecheckout-cart {
        & .simplecheckout-cart_btn {
            margin-left: 5px;
            background: $vc;
            color: #fff;
            border-radius: 4px;
            @include transitionAll;
            &:hover {
                background: $dvc;
            }
        }
    }
}

.simple-content {
    margin-top: 10px;
    & .checkout-heading  {
        background: $vc;
        color: $mainFontColor;
    }
}

#simplecheckout_cart {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
