.contacts {
    & .section-title {
        margin-bottom: 20px;
    }
    &-data {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        padding: 20px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        @include mw(650px) {
            flex-direction: column;
        }
    }
    &-data_item {
        display: flex;
        flex-direction: column;
        text-align: center;
        @include mw(650px) {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    &-data_item-title {
        width: 100%;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
    }
    &-data_item-text {
        width: 100%;
    }
    &-data_map-link {
        color: $vc;
        box-shadow: none;
        @include transitionAll;
        &:hover {
            color: $dvc;
        }
    }
    &-data_item-img {
        & .fa {
            color: $vc;
            font-size: 35px;
            margin-bottom: 10px;
        }
    }
}