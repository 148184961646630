.nav-main {
    border: 1px solid $vc;
    margin-bottom: 20px;
    border-radius: 20px;
    @include mw(768px) {
        background: $vc;
        //text-align: left;
    }
    & .tll {
        color: $mainFontColor;
        //font-family: $secFont;
        font-weight: 700;
        font-size: 14px;
        @include transitionAll;
        @include mw(768px) {
            color: $mainFontColor;
            font-weight: 400;
            &:hover {
                transform: scale(1);
            }
        }
        &:hover {
            transform: scale(1.05);
        }
    }
    & .tlli {
        font-size: 14px;
    }
    & .tlli.sep {
        & .item-sep {
            font-size: 12px;
        }
    }
    & .mobile-trigger {
        background: 97% center no-repeat;
    }
    & .superdropper {
        & span {
            margin-top: 4px;
            color: $mainFontColor;
        }
    }
    &_header {

    }
    &_navbar {
        display: inline-block;
        vertical-align: middle;
        @include mw(768px) {
            display: block;
        }

    }
    &_list-link {

    }
    &_category {
        color: $vc;
    }
    &_list-item {
        padding: 5px;
        color: $vc;
        cursor: pointer;
        @include transitionAll;
        &:hover {
            color: $dvc;
        }
    }
    //styles in bootstrap and stylesheet_default
    &_link {
        border: 1px solid $vc;
        border-radius: 15px;
        //text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        //padding: 10px 25px 10px 10px;
        //min-height: 15px;
        //background-color: transparent;
        @include mw(768px) {
            border: none;
        }
    }
    &_drop-item {
        color: $vc;
        position: relative;
        padding: 5px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        cursor: pointer;
        @include transitionAll;
        &:hover {
            color: $dvc;
        }
        & .icon {
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            font-size: 10px;
            color: $vc;
            @include mw(768px) {
                font-size: 14px;
                top: 12px;
                right: 10px;
                transform: translate(0);
            }
        }
    }
    &_see-all {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    // supermenu styles
    & .nav_switch-btn_wrapper {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 50px;
        background: #fff;
        opacity: 0.8;
        transition: all 0.3s;
        &:hover:not(.nav_switch-wrapper) {
            background: $dvc;
            opacity: 1;
        }
        &:hover:not(.nav_switch-wrapper) .icon {
            fill: #fff;
        }
    }
    & .nav_switch-btn {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;

        text-align: center;
        & .fa {
            display: block;
            font-size: 21px;
            color: $vc;
        }
    }
    & .nav_switch-prev {
        top: 0;
        & .nav_switch-btn {
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }
    & .nav_switch-next {
        bottom: 0;
        & .nav_switch-btn {
            transform: translate(-50%, -50%);
        }
    }
    & .tlli.mkids {
        z-index: 1000;
        & .tll .fa {
            margin-left: 5px;
            @include mw(768px) {
                display: none;
            }
        }
    }
    & .bigdiv {
        //display: block !important;
        width: 275px;
        padding: 50px 0;
        z-index: 1000;
        position: relative;
        & .withchildfo {
            background: #fff;
            &:hover {
                background: $dvc;
            }
            &:hover .theparent {
                color: #fff;
            }
            & .theparent {
                padding: 5px 20px 5px 15px;
                color: $mainFontColor;
                font-size: 14px;
            }
        }

        & .withchildfo > .flyouttoright {
            left: 275px;
        }
        & .withchildfo.hasflyout {
            padding-right: 30px;
            background-position: 97% 50%;
            @include mw(768px) {
                padding-right: 0;
            }
            & .superdropper {
                & span {
                    @include mw(768px) {
                        color: $vc;
                    }
                }
            }
        }
    }
}
