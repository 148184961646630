.main-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    &_title {
        width: 100%;
        margin: 10px 0;
        //font-family: $secFont;
        font-weight: 900;
        //text-transform: uppercase;
        font-size: 16px;
        @include mw(768px) {
            font-size: 14px;
        }
    }
    &_top {
        width: 100%;
    }
    &_content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & .text-danger {
            margin-top: 10px;
            color: #ff0000;
        }
    }
    &_required-group {
        & .main-form_label {
            &:before {
                content: '* ';
                color: #F00;
                font-weight: bold;
            }
        }
    }
    &_full-group {
        width: 100%;
        margin-bottom: 15px;
    }
    &_half-group {
        width: 45%;
        padding: 0 10px;
        margin-bottom: 15px;
    }
    &_label {
        font-weight: 700;
        text-align: right;
        margin-bottom: 10px;
    }
    &_input-wrapper {
        padding: 10px 15px;
        //transform: skew(-20deg);
        transition: all 0.3s;
        cursor: pointer;
        border: 1px solid $bc;
        &:hover {
            //background-color: $bc;
        }
        &:hover .main-form_input {
            //color: #fff;
        }
    }
    &_input {
        width: 100%;
        padding: 10px 12px;
        background: none;
        border: 1px solid $vc;
        outline: none;
        border-radius: 10px;
        font-size: 12px;
        color: $mainFontColor;
        &::placeholder {
            font-size: inherit;
        }
    }
    &_select {
        width: 100%;
        padding: 10px 10px;
        background: none;
        border: 1px solid $vc;
        outline: none;
        border-radius: 10px;
        font-size: 12px;
        color: $mainFontColor;
        &::placeholder {
            font-size: inherit;
        }
    }
    &_file-btn {
        background: #fff;
        color: $mainFontColor;
        border: 1px solid $vc;
        border-radius: 10px;
        @include transitionAll;
        &:hover {
            background: $dvc;
            color: #fff;
        }
    }
    &_date-wrapper {
        position: relative;

    }
    &_date-btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: none;
        border: none;
        outline: none;
    }
    &_textarea {
        width: 100%;
        height: 160px;
        padding: 15px 10px;
        border-radius: 10px;
        border: 1px solid $vc;
        outline: none;
        resize: none;
        background: none;
    }
    &_footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        @include mw(768px) {
            margin-bottom: 20px;
        }
        @include mw(500px) {
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
        }
    }
    &_rating {
        margin-right: 10px;
        @include mw(500px) {
            width: 100%;
            margin-right: 0;
            justify-content: flex-end;
            margin-bottom: 10px;
        }
    }
    &_rating-title {
        margin-right: 10px;
        line-height: 1;
    }
    &_btn {
        display: flex;
        padding: 7px 10px;
        color: $mainFontColor;
        outline: none;
        border: none;
        background: $vc;
        border-radius: 15px;
        transition: all 0.5s;
        @include mw(500px) {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        &:hover {
            background: $dvc;
        }
        &:hover .btn_text {
            color: #fff;
        }
        &:hover .icon {
            color: #fff;
        }
        & .icon {
            margin-right: 10px;
        }
    }
    &_back-btn {
        color: #fff;
        background: $vc;
        border-radius: 15px;
    }
}

.star_rating {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-family: 'FontAwesome';
    &:not(:checked) {
        > input {
            position: absolute;
            clip: rect(0,0,0,0);
        }
        > label {
            width: 1em;
            //padding: 5px;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
            font-size: 17px;
            color: $vc;
            &:before {
                content: '\f006  ';
            }
            &:hover,
            &:hover ~ label {
                color: $vc;
                &:before {
                    content: '\f005  ';
                }
            }
        }
    }
    > input:checked {
        & ~ label {
            &:before {
                content: '\f005  ';
            }
        }
    }
    > label:active {
        position: relative;
        top: 2px;
    }
}

 // product-category_results product-category_result-item
