//.filterpro-mobile {
//    margin-bottom: 70px;
//    & .filterpro_main-title {
//        top: 0;
//        text-align: left;
//        border-bottom-left-radius: 20px;
//        border-bottom-right-radius: 20px;
//        &:after {
//            @include mw(900px) {
//                display: block;
//            }
//        }
//    }
//    #filterpro {
//        visibility: hidden;
//        height: 0;
//    }
//}
//
//.filterpro-mobile_open {
//    & .filterpro_main-title {
//        border-bottom-left-radius: 0;
//        border-bottom-right-radius: 0;
//    }
//    & #filterpro {
//        margin-top: -50px;
//        visibility: visible;
//        height: auto;
//    }
//}

.filterpro-mobile {
    .filterpro {
        display: none;
    }
    #filterpro {
        visibility: hidden;
        height: 0;
    }
}

.filterpro-mobile_open {
    .filterpro {
        display: block;
    }
    & .filterpro_main-title {
        &:after {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    & #filterpro {
        visibility: visible;
        height: auto;
    }
}