.nav-top {
    padding: 10px 0;
    background: $vc;
    color: #fff;
    border-bottom: 1px solid $vc;
    & .container {
        padding: 0 20px;
        @include mw(480px) {
            padding:  0 5px;
        }
    }
    &_list-link {
        color: $mainFontColor;
        transition: all 0.3s;
        &:hover {
            color: $dvc;
        }
        &:focus {
            color: $dvc;
        }
        & .fa {
            font-size: 20px;
            @include mw(768px) {
                font-size: 18px;
            }
            @include mw(480px) {
                font-size: 14px;
            }
        }
    }
    &_list-item {
        color: #fff;
    }
    &_dropdown-link {
        color: $mainFontColor;
        &:hover {
            color: $dvc;
        }
    }
    & .language_drop-menu {
        border-radius: 10px;
        padding: 0;
        overflow: hidden;
    }
    & .currency_drop-menu {
        border-radius: 10px;
        padding: 0;
        overflow: hidden;
    }
    & .currency_drop-btn,
      .language_drop-btn {
        padding: 6px;
        background: none;
        color: $mainFontColor;
    }
    & .language_drop-btn {
        & img {
            margin-right: 5px;
        }
    }
    & .currency {
        margin-right: 5px;
    }
}

.header {
    &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        flex-wrap: wrap;
        @include mw(768px) {
            justify-content: center;
        }
    }
    &_logo-wrapper {
        display: block;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        @include mw(768px) {
            max-width: 150px;
            margin-bottom: 20px;
        }
        @include mw(480px) {
            max-width: 100px;
            margin-bottom: 10px;
        }
        & img {
            @include imageCenterX;
        }
    }
    &_search-wrapper {
        position: relative;
        @include mw(768px) {
            width: 100%;
        }
    }
    &_search-btn-wrapper {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        right: 4%;
        transform: translateY(-70%);
        z-index: 5;
        @include mw(480px) {
            right: 6%;
        }
    }
    &_search-input {
        width: 100%;
        font-size: 16px;
        border-radius: 20px;
        padding: 20px 40px 20px 20px;
        outline: none;
        border: 2px solid $vc;
        @include transitionAll;
        &:hover,
        &:focus {
            box-shadow: 0 0 11px 4px rgba(189, 40, 106, 0.4);
        }
    }
    &_search-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: none;
        border: none;
        outline: none;
        z-index: 5;
        color: $vc;
        @include transitionAll;
        &:hover {
            transform: translate(-50%, -50%);
            color: $dvc;
        }
    }
    &_cart-wrapper {
        @include mw(768px) {
            width: 100%;
        }
    }
    & .live-search {
        width: 100%;
        margin-top: 0;
        padding-bottom: 0;
        left: 0;
        border-radius: 15px;
        border: 1px solid $vc;
        & ul {
            max-height: 300px;
            overflow-y: auto;
            margin-bottom: 20px;
            & li {
                min-height: 55px;
                position: relative;
                & a {
                    position: relative;
                }
                & .product-name {
                    font-weight: 700;
                    width:70%;
                }
                & .product-price {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    font-weight: 700;
                }
            }
        }
        & .result-text {
            background: $vc;
        }
        & .view-all-results {
            color: #fff;
            &:hover {
                color: $dvc;
            }
        }
    }
}

.header-cart {
    &_btn {
        border-radius: 20px;
    }
    &_list {
        border-radius: 20px;
        overflow: hidden;
    }
    &_header-table {
        & tbody {
            & tr {
                background: $vc;
                & td {
                    vertical-align: middle;
                    border-top: none;
                    color: $mainFontColor;
                }
            }
        }
    }
    &_btn-remove {
        border-radius: 10px;
    }
    &_proceed-btn {
        color: $mainFontColor;
        transition: all 0.3s;
        @include mw(480px) {
            font-size: 11px;
        }
        &:hover {
            color: $vc;
        }
    }
    &_basket-link {
        margin-right: 10px;
    }
}
