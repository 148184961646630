.product-main {
    &_gallery {

    }
    &_subtitle {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    &_btn-group {
        width: 100%;
        display: flex;
        & .btn_wish {
            margin-right: 10px;
        }
    }
    &_view-link {
        @include transitionAll;
        &:hover {
            border-color: $vc;
        }
    }
    &_gallery-link {
        @include transitionAll;
        &:hover {
            border-color: $vc;
        }
    }
    &_data-list {
        margin-bottom: 20px;
    }
    &_right-part {
        margin-bottom: 20px;
    }
    &_data-manufacturer {
        color: $vc;
        text-decoration: underline;
        @include transitionAll;
        &:hover {
            color: $dvc;
        }
    }
    &_data-text {
        margin-right: 5px;
        font-weight: 700;
    }
    &_price-list {

    }
    &_data-value {

    }
    &_price-old {
        text-decoration: line-through;
        font-weight: 700;
    }
    &_price-special {
        font-weight: 700;
    }
    &_tax-value {
        font-weight: 700;
    }
    &_discount-value {
        font-weight: 700;
    }
    & .btn-primary {
        border-radius: 15px;
        margin-bottom: 15px;
        &:hover {
            transform: scale(1.03);
            color: #fff;
        }
    }
    & .one-click_btn {
        background: lighten($dvc, 20%);
    }
    &_btn-add {
        background: $dvc;
    }
    &_qunatity-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
    &_quantity-btn {
        display: inline-flex;
        background: $vc;
        font-size: 18px;
        //color: $mainFontColor;
        color: $mainFontColor;
        @include transitionAll;
        &:hover {
            background: $dvc;
            //color: #fff;
        }
    }
    &_quantity-input {
        width: 55px;
        margin: 0 5px;
        border-radius: 4px;
        text-align: center;
        border: 1px solid $vc;
        &:active, &:focus {
            border: 1px solid $vc;
            outline: none;
        }
    }
    & .alert-info {
        background: $vc;
        color: #fff;
        border: none;
        border-radius: 15px;
    }
    &_rating-content {
        margin-bottom: 15px;
    }
    &_rating-stars {
        color: $vc;
        margin-bottom: 15px;
        & span {
            line-height: 1;
        }
    }
    &_rating-link {
        color: $vc;
        line-height: 1;
        @include transitionAll;
        &:hover {
            color: $dvc;
            text-decoration: underline;
        }
    }
}

.product-description {
    &_review {
        width: 100%;
        padding: 15px 0;
    }
    &_content {
        padding: 15px 0;
    }
    &_content-table {
        margin-bottom: 50px;
        & thead {
            & td {
                color: #fff;
                background: $vc;
            }
        }
        & td {
            border: 1px solid $vc;
        }
    }
    &_content-item {
        margin-bottom: 20px;
    }
    &_review-item {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 15px 10px;
        box-shadow: 0 0 11px 4px rgba(0, 165, 114, 0.4);
        border-radius: 20px;
    }
    &_review-header {
        width: 100%;
    }
    &_review-author {
        width: 100%;
        margin-bottom: 10px;
        font-weight: 700;
    }
    &_review-date {
        width: 100%;
    }
    &_review-content {
        text-align: center;
    }
    &_review-text {
        width: 100%;
        margin-bottom: 10px;
    }
    &_review-rating {
        color: $vc;
    }
}

.product-special {
    & .product-item {
        margin-bottom: 20px;
    }
}

