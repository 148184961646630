.account-main {
    display: flex;
    @include mw(768px) {
        flex-direction: column;
        width: 100%;
    }
    @include mw(480px) {
        padding-top: 0;
    }
    &_left {
        @include mw(900px) {
            order: 1;
            width: 100%;
            margin-bottom: 20px;
        }
    }
    & #column-right {
        @include mw(768px) {
            display: flex !important;
            width: 100%;
            order: 2;
        }
    }
    & .main-form_content {
        width: 100%;
    }
    &_btn {
        background: $vc;
        border-radius: 15px;
        @include transitionAll;
        color: #fff;
        &:hover {
            background: $dvc;
        }
    }
    &_subtitle {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
    }
    &_table {
        & .popup-purchase-button {
            margin-bottom: 10px;
            & button {
                color: #fff;
                padding: 10px;
                border-radius: 15px;
                background: $vc;
                border: none;
                outline: none;
                @include transitionAll;
                &:hover {
                    background: $dvc;
                }
            }
        }
        & td {
            font-size: 12px;
            @include mw(768px) {
                font-size: 14px;
            }
        }
    }
    &_agree-textual {
        @include mw(480px) {
            font-size: 12px;
            & .checkbox-custom {
                padding-left: 20px;
            }
        }
    }
    &_table-head {
        background: $vc;
        color: #fff;
    }
    & .main-form_content {
        width: 100%;
    }
}

.account-login {
    &_registr-text {
        margin-bottom: 20px;
    }
    &_forgotten-link {
        display: block;
        margin-top: 20px;
    }
}

.account-forgotten {
    &_subtitle {
        margin-bottom: 20px;
    }
}

.account-list {
    display: flex;
    flex-direction: column;
    border: 1px solid $vc;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    @include mw(768px) {
        width: 100%;
    }
    &_link {
        padding: 10px 15px;
        @include transitionAll;
        &:not(:last-child) {
            border-bottom: 1px solid $vc;
        }
        &:hover {
            color: #fff;
            background: $dvc;
            &:not(:last-child) {
                transform: translateY(-3px);
            }
        }
    }
}
