.product-category {
    display: flex;
    @include mw(900px) {
        flex-wrap: wrap;
    }
    & #column-left {
        width: 100% !important;
        display: block !important;
    }
    & .category-list {
        overflow: hidden;
        border: 1px solid $vc;
        margin-bottom: 20px;
        @include mw(768px) {
            display: none;
        }
        & .list-group-item.active {
            color: #fff;
            background: $vc;
        }
        & .list-group-item {
            display: block;
            background: none;
            border:none;
            &:not(:last-child) {
                border-bottom: 1px solid $vc;
            }
            &:hover {
                color: #fff;
                background: $vc;
            }
        }
    }
    & .owl-carousel {
        @include mw(768px) {
            display: none !important;
        }
        & .owl-wrapper-outer {
            border-radius: 20px;
        }
    }
    &_col-left {
        width: 24%;
        min-width: 280px;
        margin-right: 20px;
        @include mw(900px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
        }
        @include mw(480px) {
            margin-bottom: 20px;
        }
        & #column-left {
            padding: 0;
        }
    }
    &_col-right {
        width: 75%;
        position: relative;
        z-index: 20;
        @include mw(900px) {
            width: 100%;
        }
        & .about .container {
            padding: 0;
        }
    }
    &_sort {
        display: flex;
        justify-content: flex-end;
        margin: 28px 0;
        @include mw(600px) {
            font-size: 16px;
            flex-wrap: wrap;
        }
        & select::-ms-expand {
            display: none; /* remove default arrow on ie10 and ie11 */
        }
        & .icon {
            fill: #000;
            font-size: 11px;
            position: absolute;
            transform: rotate(-90deg);
        }
    }

    &_subtitle {
        margin-bottom: 10px;
    }
    &_list-wrapper {
        margin-bottom: 10px;
        @include mw(1100px) {
            width: 50%;
        }
        @include mw(768px) {
            width: 100%;
        }
    }
    &_list-item {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
    &_list-link {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 15px;
        color: $mainFontColor;
        background: $vc;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        &:hover {
            transform: translateY(-3px);
        }
    }
    &_list-img-wrapper {
        display: block;
        min-width: 50px;
        min-height: 50px;
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    &_list-img {
        @include imageCenterFixed;
    }
    &_descr-img {
        border-radius: 15px;
    }
    &_sort-wrapper {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        align-items: center;
        @include mw(600px) {
            flex-wrap: wrap;
        }
        & .input-group-addon {
            background: none;
            border: none;
        }
    }
    &_compare-wrapper {
        padding: 15px 0;
        margin-right: 20px;
        @include mw(1100px) {
            display: none;
        }
    }
    &_sort-view {
        @include mw(768px) {
            display: none;
        }
        & .active {
            background: $dvc;
        }
    }
    &_sort-type {
        @include mw(600px) {
            order: 3;
            flex-grow: 1;
            margin-bottom: 10px;
        }
    }
    &_sort-amount {
        @include mw(600px) {
            //flex-basis: 60%;
            order: 2;
            margin-bottom: 10px;
        }

    }
    &_sort-text {
        text-transform: uppercase;
        margin-right: 30px;
        font-size: 18px;
        @include mw(600px) {
            width: 100%;
            font-size: 16px;
            text-transform: none;
            margin-bottom: 10px;
            margin-right: 0;
        }
    }

    &_sort-select {
        min-width: 150px;
        padding-right: 30px;
        border: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        background: url(../image/custom/arrow_shevron.png)  no-repeat;
        background-size: 11px 6px;
        background-position: 98% 50%;
        cursor: pointer;
        font-size: 18px;
        @include mw(600px) {
            font-size: 16px;
            text-transform: none;
            //border: 1px solid lighten($hovbc, 10%);
            width: 100%;
            padding: 15px;
        }
    }
    &_results {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        //border-left: 1px solid $bordc;
    }
}
