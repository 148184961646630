.newsblog_acticles {
    &_content-wrapper {
        display: flex;
        justify-content: space-between;
        @include mw(600px) {
            flex-wrap: wrap;
        }
    }
    & .news-item {
        width: 49%;
        @include mw(600px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    & .section-title {
        margin-bottom: 20px;
    }
}

#content.news-category {
    @include mw(768px) {
        padding-bottom: 20px;
    }
}

.news-category  {
    padding-bottom: 20px;
    &_results-wrapper {
        & .news-item {
            margin-bottom: 20px;
            //width: 49%;
            @include mw(600px) {
                width: 100%;
            }
        }
    }
}

.news-article {
    &_date {
        margin-bottom: 20px;
    }
    &_share-wrapper {
        margin-bottom: 20px;
    }
    &_btn-group {
        margin-bottom: 20px;
    }
    &_share-text {
        margin-bottom: 20px;
    }
}

.news-item {
    //display: flex;
    //flex-direction: column;
    padding: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

    &_img-link {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 20px;
    }
    &_img {
        @include imageCenterX;
    }
    &_title-link {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 20px;
        font-family: $secFont;
        @include transitionAll;
        @include mw(768px) {
            font-size: 14px;
        }
        &:hover {
            color: $dvc;
        }
    }
    &_date {
        margin-bottom: 20px;
        font-weight: 700;
    }
    &_caption {
        width: 100%;
        margin-bottom: 20px;
    }
    & .main-form_btn {
        width: 60%;
        @include mw(1268px) {
            width: 100%;
        }
        & .icon {
            margin-right: 10px;
        }
    }
}
