html {
    line-height: $mainLineHeight;
    font-size: $mainFontSize;
    font-family: $mainFont;
    color: #fff;
    @media screen and (max-width: $desktopWidth) {
        font-size: 16px;
    }
    @media screen and (max-width: $tableWidth) {
        font-size: 15px;
    }
    @media screen and (max-width: $phoneWidth) {
        font-size: 14px;
    }
}

body {
    font-weight: $mainFontWeight;
}

body.mfp-active {
    overflow: hidden;
    //-webkit-overflow-scrolling: auto;
    width: 100%;
    position: fixed;
    //overflow: auto;
    & .mfp-container {
        //overflow: auto;
    }
}
body .mfp-wrap {
    //-webkit-overflow-scrolling: touch;
    -webkit-overflow-scrolling: auto;
    -webkit-transform: translateZ(0);
    position: fixed;
    overflow: auto;
    top: 0 !important;
}


#content {
    @include mw(768px) {
        padding: 0;
    }
}

.content-main {
    min-height: 600px;
}

.section-title {
    font-family: $secFont;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    @include mw(768px) {
        font-size: 20px;
    }
}

.section-subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
}

.section {
    position: relative;
    padding: 0 0 30px;
    @media screen and (max-width: $desktopWidth) {
        padding: 20px 0;
    }
}
.container {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    & .alert-success {
        background: $vc;
        color: #fff;
    }
}

.row-overflow {
    overflow: hidden;
}

.bg-primary {
    background: $pc;
}

.radius_sm {
    border-radius: 10px;
}

.radius_md {
    border-radius: 20px;
}

.radius_lg {
    border-radius: 40px;
}

.link {
    color: $vc;
    line-height: 1;
    @include transitionAll;
    &:hover {
        color: $dvc;
        text-decoration: underline;
    }
}

.btn {
    @include button;
    cursor: pointer;
    transition: all 0.5s;
    &_text {
        @include textOverflow;
    }
    &_sm {
        width: 50px;
    }
    &_md {
        width: 135px;
    }
    &_lg {
        width: 200px;
    }
    &_bordered {
        border: 1px solid $bc;
    }
    &_violet-hover {
        transition: all 0.3s;
        &:hover {
            background: $dvc;
        }
    }
    &_add {
        display: inline-flex;
        padding: 5px 0;
        background: $dvc;
        color: #fff;
        @include transitionAll;
        &:hover {
            transform: scale(1.03);
        }
        & .fa {
            margin-right: 10px;
        }
    }
    &_compare {
        display: inline-flex;
        padding: 5px 0;
        background: $vc;
        color: #fff;
        transition: all 0.3s;
        &:hover {
            background: $dvc;
            color: #fff;
        }
    }
    &_wish {
        display: inline-flex;
        padding: 5px 0;
        background: $vc;
        color: #fff;
        transition: all 0.3s;
        &:hover {
            background: $dvc;
            color: #fff;
        }
    }
}

.user-input-field {
    & ul {
        list-style: disc;
        padding-left: 20px;
    }
    & a {
        color: #0000EE;
        cursor: pointer;
        text-decoration: underline;
    }
    & a:visited {
        color: #551A8B;
    }
    & ol {
        list-style: decimal;
        padding-left: 20px;
    }
    & img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
    }
    & table {
        border: 1px solid $bc;
        & td {
            border: 1px solid $bc;
            padding: 10px;
        }
    }
}

.user-input_table-wrapper {
    overflow: auto;
}

/*CUSTOM CHECKBOX STYLES V2 START*/
.checkbox-custom {
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    padding-left: 35px;
    & .checkbox {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    & .radio {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    &_blocked {
        display: block;
    }
    &_inlined {
        display: inline-block;
    }
    &_flexed {
        display: flex;
    }
    &_checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        border: 1px solid $vc;
        background: #fff;
        transform: translateY(-50%);
        font-size: 0;
        &:after {
            content: '';
            position: absolute;
            left: 4px;
            top: 1px;
            border: 1px solid #fff;
            border-radius: 0;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &_radio {
        position: absolute;
        top: 50%;
        left: 0;
        border: 1px solid $vc;
        border-radius: 50%;
        background: #fff;
        transform: translateY(-50%);
        font-size: 0;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }
    }
    &_sm-radio {
        width: 16px;
        height: 16px;
        &:after {
            width: 8px;
            height: 8px;
        }
    }
    &_sm-checkbox {
        width: 15px;
        height: 15px;
        &:after {
            width: 5px;
            height: 12px;
        }
    }
    & input:disabled ~ &_checkmark {
        background: gray;
        border: none;
        &:after {
            display: none;
        }
    }
    & input {
        top: 0;
        left: 0;
        opacity: 0;
    }
    & input:checked ~ .checkmark-colored {
        background: $vc;
    }
    & input:checked ~ .radio-colored {
        &:after {
            background: $vc;
        }
    }
    & input:checked ~ &_checkmark:after {
        display: block;
    }
}

/*CUSTOM CHECKBOX STYLES V2 END*/
.slideshow-carousel {
    & .owl-wrapper-outer {
        border-radius: 20px;
    }
}

.thumbnails {
    margin-left: -20px;
}
.thumbnails {
    overflow: auto;
    clear: both;
    list-style: none;
    padding: 0;
    margin: 0;
}

.thumbnails .image-additional {
    float: left;
    margin-left: 20px;
}

.btn-primary {
    color: #ffffff;
    background-color: $vc;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
    background-color: $dvc;
}

.main-social {
    &_btn .fa,
      a .fa,
      a.fa {
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 50%;
        border: 1px solid $vc;
        position: relative;
        @include transitionAll;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $vc;
            font-size: 16px;
        }
        &:hover {
            background: $dvc;
            box-shadow: none;
            &:before {
                color: #fff;
            }
        }
    }
    &_btn {
        padding: 0;
        background: none;
        outline: none;
        border: none;
    }
}

#content.site-map_section {
    padding-bottom: 30px;
}

.alert-danger {
    background: $vc;
    color: #fff;
}
