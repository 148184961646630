.product {
    position: relative;
    clear: both;
    &-slider {
        @include mw(1440px) {
            padding: 0 50px;
        }
        @include mw(1280px) {
            padding: 20px 50px;
        }
        @include mw(768px) {
            padding: 20px 0;
        }
    }
    & .swiper-container {
        width: 100%;
        //padding: 0 10px;
        @include mw(600px) {
            padding: 0;
        }
    }
    & .swiper-wrapper {
        & .product_title-link {
            @include mw(600px) {
                min-height: initial;
            }
        }
        & .product_description {
            @include mw(600px) {
                min-height: initial;
            }
        }
    }
    &.row {
        & .product_title-link {
            @include mw(768px) {
                min-height: initial;
            }
        }
        & .product_description {
            @include mw(768px) {
                min-height: initial;
            }
        }
        & .product-layout {
            margin-bottom: 20px;
        }
    }
    &_item {
        padding: 10px;
    }
    &_img-link {
        min-height: 50px;
        display: block;
        position: relative;
        margin-bottom: 20px;
    }
    &_img {
        @include imageCenterX;
    }
    &_title-link {
        min-height: 90px;
        display: block;
        font-weight: 700;
        font-family: $secFont;
        margin-bottom: 10px;
    }
    &_caption {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0;
    }
    &_description {
        width: 100%;
        min-height: 100px;
        font-size: 14px;
        margin-bottom: 10px;
        @include mw(768px) {
            font-size: 12px;
        }
    }
    &_price {
        width: 100%;
        align-self: flex-end;
        font-weight: 700;
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
        @include mw(768px) {
            margin-bottom: 20px;
        }
        @include mw(480px) {
            font-size: 12px;
        }
    }
    &_price-new {

    }
    &_price-old {

    }
    & .product-thumb {
        padding: 15px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border: none;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    }
    & .swiper-nav {
        background-image: none;
        & .fa {
            font-size: 40px;
        }
        @include mw(768px) {
            display: none;
        }
    }
    & .swiper-button-prev {
        left: -40px;
        @include mw(1440px) {
            left: 10px;
        }
    }
    & .swiper-button-next {
        right: -40px;
        @include mw(1440px) {
            right: 10px;
        }
    }
    & .popup-purchase-button {
        width: 100%;
        margin-bottom: 10px;
    }
    & .popup-purchase-button button {
        padding: 5px 0;
        background: lighten($dvc, 20%);
        color: #fff;
        transition: all 0.3s;
        font-size: 14px;
        &:hover {
            transform: scale(1.03);
            color: #fff;
        }
    }
    & .button-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    & .btn_add {
        width: 58%;
    }
    & .btn_wish,
    & .btn_compare {
        width: 18%;
    }
    & .rating {
        color: $vc;
    }
}

.product-list {
    & .product-thumb {
        display: flex;
        justify-content: space-between;
        @include mw(768px) {
            flex-direction: column;
        }
    }
    & .img-link {
        flex-basis: 20%;
        & img {
            @include imageCenterX;
        }
    }
    & .caption {
        flex-basis: 50%;
        flex-grow: 1;
        justify-content: center;
        padding: 15px;
    }
    & .title-link {
        min-height: initial;
    }
    & .description {
        margin-bottom: 20px;
    }
    & .button-group {
        flex-basis: 25%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @include mw(768px) {
            flex-basis: 100%;
            flex-direction: row;
        }
        & .btn {
            border-radius: 15px;
            padding: 10px 0;
            width: 100%;
            @include mw(768px) {
                &:not(.main-form_btn) {
                    border-radius: 4px;
                }
                &_add {
                    width: 60%;
                }
                &_wish {
                    width: 20%;
                }
                &_compare {
                    width: 20%;
                }
            }
            &:not(:last-child) {
                margin-bottom: 20px;
                @include mw(768px) {
                    margin-bottom: 0;
                }
            }
        }
        & .popup-purchase-button {
            margin-bottom: 20px;
            & button {
                border-radius: 15px;
                padding: 10px 0;
                @include mw(768px) {
                    border-radius: 4px;
                }
            }
        }
    }
}
