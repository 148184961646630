// footer style
.footer {
    &_row {
     margin: 20px 0 15px;
    }
    &_hr {
        @include mw(768px) {
            display: none;
        }
    }
    &_item {
        padding-left: 0;
        padding-bottom: 10px;
        @include mw(768px) {
            border-bottom: 1px solid #fff;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    &_menu-title {
        font-weight: 700;
        font-size: 16px;
    }
    &_container {
        width: 100%;
        position: relative;
        max-width: 1440px;
        margin: 0 auto;
    }
    &_bottom-line {
        padding: 40px 15px;
        @include mw(768px) {
            padding: 20px 10px;
        }
    }
    &_bottom-textual {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #a3a3a3;
        position: relative;
        @include mw(768px) {
            flex-wrap: wrap;
        }
    }
    &_bottom-text {
        @include mw(768px) {
            margin-bottom: 10px;
        }
    }
    &_bottom-link {
        max-height: 100%;
        display: flex;
        align-items: center;
        margin-left: auto;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        @include mw(768px) {
            width: 100%;
            bottom: 0;
            margin-left: 0;
            position: relative;
            justify-content: center;
            transform: translate(0);
        }
    }
    &_bottom-link-text {
        max-height: 20px;
        line-height: 20px;
        margin-right: 10px;
    }
    &_bottom-img {
        @include imageCenterFluid;
    }
    & .main-social {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include mw(768px) {
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0);
            text-align: center;
        }
    }
}
