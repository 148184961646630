// Font
$mainFont: 'Kurale', Helvetica, sans-serif;
$secFont: 'Marmelad', Helvetica, sans-serif;

// Size
$mainFontColor: #333;
$sfc: #555;
$mainFontSize: 16px;
$mainFontWeight: 400;
$mainLineHeight: 1.5;

// BreakPoints
$lgDesktopWidth: 1920px;
$desktopWidth: 1280px;
$smDesktopWidth: 980px;
$tableWidth: 768px;
$phoneWidth: 480px;
$smPhoneWidth: 320px;

// Secondary breakpoints

$break_lg1: 1685px;
$break_lg2: 1445px;
$break_dt1: 1280px;
$break_dt2: 992px;
$break_md1: 768px;
$break_md2: 620px;
$break_sm1: 480px;
$break_sm2: 400px;
$break_xs1: 380px;
$break_xs2: 320px;

// Colors
//$vc: #50c878;
//$dvc: #00a572;
//$vc1: #fbd6d7;
//$dvc1: #с83f71;

$vc: #fbd6d7;
$dvc: #bd286a;

$pc: #f5d5c0;
$bc: #777;
$lorc: #ff5a00;

//$slgc: #e8e8e8;
//$lgc: #777;
//$orc: #e97517;

//$lbc: #2579c9;
//$dbc: #105ba4;
//$hovbc: #5a91c7;
//$lpc: #42576c;
//$dpc: #1c2e40;
//$slbc: #e5eef7;
//$bgc: #edeff5;
//$bordc: #dee7ef;
//$gbordc: rgba(129, 129, 129, 0.2);
//$slgc: #666666;
//$hlgc: #ededed;

$primary-color: #444ce0;
$success-color: #26de81;
$danger-color: #fc5c65;
$warning-color: #fed330;
$light-color: #999999;
