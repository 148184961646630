.filterpro {
    &_content-wrapper {
        border: 1px solid $vc;
        background: #fff;
        border-radius: 20px;
        overflow: hidden;
    }
    &_item {
        padding: 20px;
        margin: 0;
    }
    &_item-title {
        margin-bottom: 7px;
        font-size: 16px;
    }
    &_main-title {
        background: $vc;
    }
    &_main-title {
        padding: 15px 20px;
        position: relative;
        top: 0;
        font-size: 18px;
        font-weight: 700;
        @include mw(900px) {
            display: block;
        }
        &:after {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            transition: all 0.3s;
        }
    }
    &_range {
        & #slider-range {
            position: relative;
            border: none;
            height: 3px;
            margin: 10px 10px 10px 14px;
            .ui-slider-range {
                background: $vc;
            }
            & .ui-slider-handle {
                width: 0.9em;
                height: 0.9em;
                top: -7px;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0px 0px 5px 5px $vc;
                outline: none;
            }
        }
    }
    &_range-delimit {
        border-bottom: 1px solid $vc;
        width: 20px;
        height: 2px;
    }
    &_range-limits {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
    }
    &_range-name {
        margin-bottom: 20px;
    }
    &_range-min {
        flex-basis: 44%;
        padding: 6px 0;
        border-radius: 15px;
        border: 1px solid $vc;
    }
    &_range-max {
        flex-basis: 44%;
        padding: 6px 0;
        border-radius: 15px;
        border: 1px solid $vc;
    }
}

.filtered-wrapper {
    position: relative;
    margin-bottom: 10px;
}

.filtered {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    margin: 10px 0 0 20px;
}

.filtered[disabled="disabled"] + label:before {
    background: $bc;
}

.filtered + label {
    position: relative;
    padding: 0 0 0 30px;
    cursor: pointer;
}

.filtered + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border: 1px solid #ccc;
    background: transparent;
}

.filtered + label:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.filtered:checked + label:before {
    background: $vc;
}
.filtered:checked + label:after {
    display: block;
}
