.blog-article {
    @include mw(480px) {
        padding-top: 0;
    }
    & .section-title {
        margin-bottom: 20px;
    }
    &_content-wrapper {
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    }
}