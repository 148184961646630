.product-compare {
    & .main-form_btn {
        width: 100%;
        margin-bottom: 20px;
    }
    &_btn-group {
        & .btn,
          .popup-purchase-button {
            display: block;
            max-width: 250px;
            margin: 0 auto;
            color: $mainFontColor;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
    & .popup-purchase-button {
        width: 100%;
        & button {
            padding: 10px;
            border: none;
            outline: none;
            background: $vc;
            border-radius: 20px;
            font-size: 14px;
            @include transitionAll;
            &:hover {
                background: $dvc;
            }
        }
    }
    &_table-header {
        background: $vc;
        color: #fff;
    }
}

.compare {
    & .fa {
        color: $dvc;
    }
    &_table-wrapper {
        overflow-x: auto;
    }
    &_table {
        @include mw(768px) {
            width: 768px;
        }
    }
}
