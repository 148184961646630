.breadcrumb {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    padding: 8px;
    background: $vc;
    @include mw(500px) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        font-size: 12px;
    }
    &_item {
        position: relative;
        text-align: center;
        color: $mainFontColor;
        font-size: 12px;
        padding: 0 25px;
        @include mw(500px) {
            padding: 5px 15px;
            &:after {
                width: 8px;
                height: 8px;
            }
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: 24px;
            height: 24px;
            border-right: 1px solid $mainFontColor;
            border-bottom: 1px solid $mainFontColor;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
    &_link {
        @include transitionAll;
        &:hover {
            color: $dvc;
        }
    }
}
